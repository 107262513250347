<template>
  <q-form ref="editForm">
    <c-card title="관련자료" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-12">
          <c-upload
            :attachInfo="attachInfo"
            :editable="editable && !disabled"
            label="자체감사 관련자료">
          </c-upload>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
export default {
  name: 'run-doc',
  props: {
    param: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
    planData: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
        chgUserId: '',
        sysApprovalRequestId: '',
        approvalTypeCd: '',
        approvalStatusCd: '',
        approvalStatusName: '',
        processList: [],
        equipList: [],
        deptList: [],
        execList: [],
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SELF_INSPECTION',
        taskKey: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.param.selfInspectionStatusCd != 'SISC000002' || this.planData.approvalStatusCd === 'ASC0000001';
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$set(this.attachInfo, 'taskKey', this.param.selfInspectionResultId)
    },
  }
};
</script>
